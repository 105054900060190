import React from 'react'
import DataTable from "../components/Datatable/DataTable"
import {useTranslation} from "react-i18next";
import {COLOR_PRIMARY} from 'gatsby-env-variables'

const LanguagesPage = ({ pageContext: { languages } }) => {

  const { t } = useTranslation()

  return (
      <div>
      <DataTable
          data={languages}
          columns={[
            {accessor: 'iso', Header: 'iso',hidden:true},
            {accessor: 'name', Header: t('fields.name'),
                Cell: ({ row }) => (
                    <a className={`text-primary-600`} href={'/languages/' + row.values.iso}>
                        <div className={`text-primary-600 w-60 overflow-ellipsis overflow-hidden`}>{row.values.name}</div>
                    </a>
                )},
          ]} />
      </div>
  );
};

export default LanguagesPage